// Extra small screen / phone
//** Deprecated `@screen-xs` as of v3.0.1
@screen-xs:                  480px;
//** Deprecated `@screen-xs-min` as of v3.2.0
@screen-xs-min:              @screen-xs;
//** Deprecated `@screen-phone` as of v3.0.1
@screen-phone:               @screen-xs-min;

// Small screen / tablet
//** Deprecated `@screen-sm` as of v3.0.1
@screen-sm:                  768px;
@screen-sm-min:              @screen-sm;
//** Deprecated `@screen-tablet` as of v3.0.1
@screen-tablet:              @screen-sm-min;

// Medium screen / desktop
//** Deprecated `@screen-md` as of v3.0.1
@screen-md:                  992px;
@screen-md-min:              @screen-md;
//** Deprecated `@screen-desktop` as of v3.0.1
@screen-desktop:             @screen-md-min;

// Large screen / wide desktop
//** Deprecated `@screen-lg` as of v3.0.1
@screen-lg:                  1200px;
@screen-lg-min:              @screen-lg;
//** Deprecated `@screen-lg-desktop` as of v3.0.1
@screen-lg-desktop:          @screen-lg-min;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max:              (@screen-sm-min - 1);
@screen-sm-max:              (@screen-md-min - 1);
@screen-md-max:              (@screen-lg-min - 1);

body{font-family: "Lato",sans-serif !important;font-weight:400;color:#58585A !important;text-align: left !important;font-size: 17px !important;}
#portal_encuesta #wpadminbar{display:none;}

.nf-form-content label{font-size: 14px !important;
line-height: 1.42857143 !important;
color:#333 !important;display: inline-block !important;
max-width: 100% !important;
margin-bottom: 5px !important;
font-weight: bold !important;}
.nf-form-content .listselect-wrap .nf-field-element div, .nf-form-content input.ninja-forms-field, .nf-form-content select.ninja-forms-field:not([multiple]) {height: 40px !important;}
.nf-form-content .label-above .nf-field-label, .nf-form-content .label-below .nf-field-label, .nf-form-content .label-hidden .nf-field-label{margin-bottom: 0px !important;}
.nf-input-limit{font-size: 14px !important;}
#loginform{max-width: 330px;
padding: 15px;
margin: 0 auto;}
#loginform .login-username label,#loginform .login-username input,
#loginform .login-password label,#loginform .login-password input,
#loginform .login-remember label,
#loginform .login-submit
{float: left;clear: both;}
//.userconectado{display: none;}
#nf-field-85{background: #FFFFFF !important;border: 0px !important;padding: 0px !important;font-weight: bold;color:#58585A !important;}

.login-username,.login-password,.login-remember{float:left;margin-bottom: 15px;}

#wp-submit{background-color:
#FF5200 !important;
border-color:
#FF5200 !important;padding: 8px 57px;
font-size: 18px;
line-height: 1.3333333;
border-radius: 6px;display: block;
color:#FFF;margin-top: 15px;}

input[type="button"]{padding: 10px 16px !important;
font-size: 18px !important;
line-height: 1.3333333 !important;
border-radius: 6px !important;background-color:#FF5200 !important;
border-color:#FF5200 !important;color: #FFFFFF !important;height: 50px !important;}

input[type="button"]:hover{color:#FFFFFF !important;}

.nf-response-msg,.nf-error-msg,.mensaje_solo_una_vez{font-size: 22px;text-align: center;color: green;padding-bottom: 15px;}
.nf-error-msg,.mensaje_solo_una_vez{color:#e80000 !important;}
#desconectar_container{padding-top: 15px;padding-bottom: 15px;text-align: right;}
#desconectar_container a{color: #FFFFFF;background-color:#FF5200 !important;border-color:#FF5200 !important;font-size: 18px !important;line-height: 1.3333333 !important;border-radius: 6px !important;float: right;padding: 10px 25px;}
#desconectar_container a:hover{text-decoration:none;}

#logo_ua {float: right;}

.logos_header {
    background-color: #FFF !important;
    padding-top: 18px;
    margin-top: 15px;
}

.text-muted{margin-top:20px;}

.enunciado{color: #58585A !important;
text-align: left !important;
font-size: 1.08em !important;margin-top:25px;margin-bottom:15px;}

.section{color: #58585A !important;
text-align: left !important;
font-size: 1.2em !important;margin-top:25px;margin-bottom:25px;font-weight:700;}

.margin_bottom_5{margin-bottom: 5px !important;}
.actividad_comentario{margin-bottom:25px;}
.enviar_encuesta,.enviar_encuesta:hover{margin-top:15px;margin-bottom:25px;color:#FFFFFF;}
.margin_bottom_35{margin-bottom: 35px !important;}

.background_yo{background-color:#FF5200 !important;color:#FFFFFF;font-weight:700;}
/*div.orgChart div.node{width:300px;}*/
div.orgChart div.node{padding:15px !important;}

/*****LOGIN PAGE*****/
.form-signin {
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .form-signin-heading{
  margin-bottom: 10px;
}

.form-signin .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
/*****FIN LOGIN PAGE*****/

.btn-primary,.btn-primary:hover,.btn-primary.disabled,.btn-primary.disabled:hover,.enviar_encuesta{background-color: #FF5200 !important;
border-color: #FF5200 !important;}

.errores_save_cuestionario{color: #A94442;font-size:1.08em;padding-bottom:25px;}

.desconectarse_cuestionario{color: #FF5200;font-size:1.2em;padding-top:25px;text-align:center;}
.desconectarse_cuestionario a{color: #FF5200;}